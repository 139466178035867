import React from "react";
import classNames from "classnames";
export default function NewSection() {
  return (
    <>
      <div className="privacy-page">
        <div className={classNames("section")}>
          <h2
            className={classNames("h-delta", "text-center")}
            id="online-privacy-notice"
          >
            Online Privacy Notice
          </h2>
          <ol>
            <li>
              <h3 className={classNames("h-echo")}>We Respect Your Privacy</h3>
              <p>
                WeVett Realty (“Company” or “We”) respect your privacy, and we
                recognize the importance of keeping your information secure.
                Below, you will find details about our privacy policies and how
                your information is used.
              </p>
              <p>
                This policy describes the types of information we may collect
                from you or that you may provide when you visit wevettrealty.com
                (our "Website" or “Site”) and our practices for collecting,
                using, maintaining, protecting, and disclosing that information.
              </p>
              <p>This policy applies to information we collect:</p>
              <ul>
                <li>On this Website.</li>
                <li>
                  In email, text, and other electronic messages between you and
                  this Website.
                </li>
                <li>
                  When you interact with our advertising and applications on
                  third-party websites and services, if those applications or
                  advertising include links to this policy.
                </li>
              </ul>
              <p>It does not apply to information collected by:</p>
              <ul>
                <li>
                  Us offline or through any other means, including on any other
                  website operated by Company or any third party (including our
                  affiliates and subsidiaries); or
                </li>
                <li>
                  Any third party (including our affiliates and subsidiaries),
                  including through any application or content (including
                  advertising) that may link to or be accessible from or on the
                  Website.
                </li>
              </ul>
            </li>
            <li>
              <h3 className={classNames("h-echo")}>
                Information We Collect and How We Use and Share Such Information
              </h3>
              <p>
                We collect information that you provide to us, and through your
                use of this Site. We collect this information when you fill out
                forms or surveys; when you complete an application with us; or
                when you visit or interact with webpages on the Site.
              </p>
              <p>
                We collect information which includes, but is not limited to:
                name; postal or email addresses; telephone; fax or mobile
                numbers; account numbers; and any other information you may
                provide to us in regards to your real estate goals. We also
                collect information that your device provides automatically,
                which includes, but is not limited to: your IP address; device
                ID; the web pages you view while visiting our Site, and how you
                interact with them; information from cookies, pixels, web
                beacons, and other online identifiers.
              </p>
              We use the Information that we collect for the following purposes:
              <ul>
                <li>to present our Website and its contents to you;</li>
                <li>
                  to provide you with information, products, or services that
                  you request from us;
                </li>
                <li>to respond to your inquiries and fulfill your requests;</li>
                <li>
                  to inform you about important information regarding the Site,
                  products or services for which you apply or may be interested
                  in applying for, or in which you are already enrolled, changes
                  to terms, conditions, and policies and/or other administrative
                  information;
                </li>
                <li>
                  to deliver marketing communications that we believe may be of
                  interest to you, including ads or offers tailored to you;
                </li>
                <li>to personalize your experience on the Site;</li>
                <li>
                  for business purposes, including data analysis, audits,
                  developing and improving products and services, enhancing the
                  Site, identifying usage trends and determining the
                  effectiveness of promotional campaigns;
                </li>
                <li>
                  for risk control, fraud detection, and fraud prevention, in
                  order to comply with laws, regulations, and other legal
                  process and law enforcement requirements; and
                </li>
                <li>
                  in response to a properly served request from a federal or
                  state law enforcement or other governmental agency as a result
                  of a subpoena or court order, in order to exercise our legal
                  rights, to protect our company or employees from threats or
                  fraud, to protect us from legal claims, or as otherwise
                  required by law. We may raise or waive any legal rights
                  available to us. This information may also be disclosed in the
                  event of a merger or sale of the company, an asset sale, or
                  the unlikely event of bankruptcy.
                </li>
              </ul>
              <p>
                We use "Cookies", "Web Beacons", and other methods to identify
                and correct errors, evaluate page popularity and statistics, and
                to personalize and improve your experience on our website. These
                technologies allow us to improve our advertising efforts and
                tailor your customer experience to reduce unnecessary ads. To
                learn more about cookies, including how to manage or delete
                cookies, please visit
                <a
                  href="http://www.allaboutcookies.org/"
                  target="_blank"
                  rel="nofollow"
                >
                  http://www.allaboutcookies.org/
                </a>
                .
              </p>
              <p>
                We may use other companies’ analytics and tracking tools that
                receive information from you when you use our Site, including
                technology from Google Analytics, Google Doubleclick, Meta
                (including but not limited to Facebook or Instagram) and others,
                to help us track, segment, and analyze usage of the Site, and to
                help us serve more targeted advertising. These tools may use
                technology such as cookies, tags, pixels, SDKs, and similar
                technologies to track online activity.
              </p>
            </li>
            <li>
              <h3>How do we handle your personal information?</h3>
              <p>
                Data will not be shared with third parties for marketing or
                promotional purposes.
              </p>
              <p>
                See
                <a href="#consumer-privacy-notice">
                  the Consumer Privacy Notice
                </a>
                for more details.
              </p>
            </li>
            <li>
              <h3 className={classNames("h-echo")}>
                Opt-Out Provisions and Consumer Choice
              </h3>
              <p>
                Once you submit your information it may not be retracted. You
                will be contacted by a representative after you submit a request
                through our Site or an affiliate Site.
              </p>
              <p>
                California "Do Not Track" Disclosures: While some Internet
                browsers have the ability to specify privacy preferences to
                websites, including requests not to track your usage and
                browsing history, WeVett Realty websites do not currently
                respond to these signals and may continue track your activity on
                this website and use gathered information as provided in this
                policy.
              </p>
              <p>
                You may opt out of the DoubleClick cookie by visiting the
                <a
                  href="https://adssettings.google.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  Google advertising opt out page
                </a>
                , or you may opt out of Google Analytics by visiting the
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="nofollow"
                >
                  Google Analytics opt-out page
                </a>
                .
              </p>
              <p>
                You can opt out of receiving interest-based advertising from
                members of the following groups by visiting their websites:
              </p>
              <ul>
                <li>
                  Digital Advertising Alliance (DAA):
                  <a
                    href="https://optout.aboutads.info/?c=2&lang=EN"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://optout.aboutads.info/?c=2&lang=EN
                  </a>
                </li>
                <li>
                  Network Advertising Alliance (NAI):
                  <a
                    href="https://optout.networkadvertising.org/?c=1"
                    target="_blank"
                    rel="nofollow"
                  >
                    https://optout.networkadvertising.org/?c=1
                  </a>
                </li>
              </ul>
              <p>
                As a result of submitting your telephone number and your request
                to receive more information, you will receive a call or text
                from WeVett Realty. You may be contacted directly by phone
                and/or email as a result of your request, even if the number you
                supplied is on the National Do Not Call registry. However, if
                you have submitted your information to us and you no longer wish
                to be contacted at the phone number(s) provided, you may
                instruct us to place you on our internal "do not call" list by
                calling us at 1-316-669-5273.
              </p>
              <p>
                WeVett Realty may periodically send you complimentary materials
                including newsletters, commercial offers, instructional videos
                or other information if you choose to provide us an email
                address. By providing your email address or submitting a request
                for more information that includes your email address, you have
                opted to receive these materials from us. If you desire to stop
                receiving these materials, please email
                <a href="mailto:customerservice@wevett.com">
                  customerservice@wevett.com
                </a>
                with the specific request.
              </p>
            </li>
            <li>
              <h3 className={classNames("h-echo")}>
                Children's Online Privacy Protection Act (COPPA):
              </h3>
              <ol style={{ listStyle: "lower-alpha" }}>
                <li>
                  This Site is intended for individuals age 18 and older.
                  Children under the age of 13 should not use this Site, and are
                  expressly prohibited from using our services.
                </li>
              </ol>
            </li>
            <li>
              <h3 className={classNames("h-echo")}>
                Our Secure System Protects You
              </h3>
              <ol style={{ listStyle: "lower-alpha" }}>
                <li>
                  To protect your information from unauthorized access and use,
                  WeVett Realty uses physical, electronic, and procedural
                  safeguards. Nevertheless, no system can be completely secure,
                  and you should not expect that your information will remain
                  protected. Unauthorized entry or use, hardware or software
                  failure, human error, or a number of other factors may
                  compromise the security of your information. As a consumer,
                  you should always safeguard your personal information and
                  protect yourself from potential identify fraud.
                </li>
              </ol>
            </li>
            <li>
              <h3 className={classNames("h-echo")}>
                General Policy Provisions
              </h3>
              <ol style={{ listStyle: "lower-alpha" }}>
                <li>
                  The policies and practices described in this disclosure are
                  subject to change, but we will communicate any such changes to
                  you as required by law. We encourage you to periodically
                  review our privacy policy for any changes.
                </li>
                <li>
                  You may receive copies of this privacy policy as well as the
                  terms and conditions by sending an email detailing your
                  request to
                  <a href="mailto:customerservice@wevett.com">
                    customerservice@wevett.com
                  </a>
                  or by sending a written request to:
                </li>
                <li>
                  Customer Service
                  <br />
                  WeVett Realty
                  <br />
                  6828 E Pepperwood St.
                  <br />
                  Wichita, KS 67226
                </li>
                <li>Call 1-316-669-5273 to speak with a representative.</li>
              </ol>
            </li>
          </ol>

          <br />
        </div>

        <div id="ccpa" className={classNames("section")}>
          <h2 className={classNames("h-delta", "text-center")} id="ccpa-notice">
            California Consumer Privacy Act Notice
          </h2>

          <p>
            <strong>Last Updated:</strong> 08/19/2024
          </p>
          <p>
            This <strong>Privacy Notice for California Residents</strong>
            supplements the information contained in WeVett Realty' Online
            Privacy Notice (
            <a href="https://wevettrealty.com/privacy/">
              https://wevettrealty.com/privacy/
            </a>
            ) and applies solely to all visitors, users, and others who reside
            in the State of California ("consumers" or "you"). We adopt this
            notice to comply with the California Consumer Privacy Act of 2018
            (CCPA) and any terms defined in the CCPA have the same meaning when
            used in this Notice.
          </p>

          <h3 className={classNames("h-echo")}>Information We Collect</h3>
          <p>
            We collect information that identifies, relates to, describes,
            references, is reasonably capable of being associated with, or could
            reasonably be linked, directly or indirectly, with a particular
            consumer, household, or device (
            <strong>"personal information"</strong>). Personal information does
            not include:
          </p>

          <ul>
            <li>Publicly available information from government records.</li>
            <li>
              Lawfully obtained, truthful information that is a matter of public
              concern.
            </li>
            <li>Deidentified or aggregated consumer information.</li>
            <li>
              Information excluded from the CCPA's scope, like:
              <ul>
                <li>
                  Personal information covered by certain sector-specific
                  privacy laws, including the Fair Credit Reporting Act (FCRA),
                  the Gramm-Leach-Bliley Act (GLBA) or California Financial
                  Information Privacy Act (FIPA), and the Driver's Privacy
                  Protection Act of 1994.
                </li>
              </ul>
            </li>
          </ul>

          <h3 className={classNames("h-echo")}>Your Personal Information</h3>
          <p>
            We do not sell the personal information, or the sensitive personal
            information we collect. We do not share the personal information, or
            sensitive personal information, that we collect with third parties
            for cross-context behavioral advertising.
          </p>

          <p>
            In particular, we collect the following categories of personal
            information and sensitive personal information listed in the tables
            below. The tables also list, for each category, our use purposes,
            and whether we sell the information or share it with third parties
            for cross-context behavioral advertising.
          </p>

          <h3 className={classNames("h-echo")}>Data Retention</h3>

          <p>
            We store your personal information for different lengths of time
            depending on the category of personal information and the purpose
            for its collection and use. We will retain your data for as long as
            needed, or permitted, based on the reason why we obtained it. We
            will not retain personal information for longer than is reasonably
            necessary for any disclosed purposes. This means we may retain your
            personal information even after you close your account with us. Some
            information may be deleted automatically based on specific
            schedules, such as financial information. Other information (e.g.,
            account information), may be retained for a longer period of time.
            Finally, we may further retain information for business practices
            based on our legitimate business interests or legal purposes, such
            as network improvement, fraud prevention, record-keeping, or
            enforcing our legal rights or defending legal claims.
          </p>
          <p>
            When deciding how long to keep your information, we consider
            criteria, such as:
          </p>
          <ul>
            <li>
              The duration, and nature, of any relationship with you or service
              that we have provided to you;
            </li>
            <li>
              Whether we are subject to any legal obligations (e.g., any laws
              that require us to keep transaction records for a certain period
              of time before we can delete them); or
            </li>
            <li>
              Whether we have taken any legal positions (e.g., in connection
              with any statutes of limitation or in response to any legal hold
              or regulatory requests).
            </li>
          </ul>

          <p>
            Rather than delete your data, we may de-identify it by removing
            identifying details.
          </p>

          <table>
            <tr>
              <th className={classNames("text-center")}>
                Personal Information Category
              </th>
              <th className={classNames("text-center")}>Business Purpose</th>
              <th className={classNames("text-center")}>Sold or Shared</th>
            </tr>
            <tr>
              <td>
                Identifiers:
                <p>
                  A real name, alias, postal address, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, account name, Social Security number, driver's
                  license number, passport number, or other similar identifiers.
                </p>
              </td>

              <td>
                Performing services, providing real estate and customer service;
                providing online products and services; for marketing and
                advertising purposes; providing customer service; protection
                against fraud.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                California Customer Records personal information:
                <p>
                  A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  passport number, driver's license or state identification card
                  number, insurance policy number, education, employment,
                  employment history, bank account number, credit card number,
                  debit card number, or any other financial information, medical
                  information, or health insurance information.
                </p>
                <p>
                  Some personal information included in this category may
                  overlap with other categories.
                </p>
              </td>
              <td>
                Protecting against fraud and providing real estate referral
                services.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Protected classification characteristics under California or
                federal law:
                <p>
                  Age (40 years or older), race, color, ancestry, national
                  origin, citizenship, religion or creed, marital status,
                  medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth and related medical conditions),
                  sexual orientation, veteran or military status
                </p>
              </td>
              <td>
                Performing services and complying with state and federal law.
              </td>
              <td>No</td>
            </tr>

            <tr>
              <td>
                Commercial information:
                <p>
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.
                </p>
              </td>
              <td>
                Providing online products and services; for marketing and
                advertising purposes; providing customer service.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Internet or other similar network activity:
                <p>
                  Browsing history, search history, information on a consumer's
                  interaction with a website, application, or advertisement.
                </p>
              </td>
              <td>
                Providing online products and services; for marketing and
                advertising purposes.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Geolocation data: <p>Physical location or movements.</p>
              </td>
              <td>
                Detecting security incidents or fraud; debugging mobile
                application or website functionality related to geolocation.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Inferences drawn from other personal information:
                <p>
                  Profile reflecting a person's preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.
                </p>
              </td>
              <td>
                Providing real estate referral and customer service; for
                marketing and advertising purposes.
              </td>
              <td>No</td>
            </tr>
          </table>

          <table>
            <tr>
              <th className={classNames("text-center")}>
                Sensitive Personal Information Category
              </th>
              <th className={classNames("text-center")}>Business Purpose</th>
              <th className={classNames("text-center")}>Sold or Shared</th>
            </tr>
            <tr>
              <td>
                Government identifiers (social security, driver's license, state
                identification card, or passport number)
              </td>
              <td>
                Providing real estate referral and customer service; providing
                online products and services; for marketing and advertising
                purposes; providing customer service; protection against fraud.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>
                Complete account access credentials (user names, account
                numbers, or card numbers combined with required access/security
                code or password)
              </td>
              <td>
                Providing online products and services; providing customer
                service.
              </td>
              <td>No</td>
            </tr>
            <tr>
              <td>Racial or ethnic origin</td>
              <td>
                Performing services and complying with state and federal law.
              </td>
              <td>No</td>
            </tr>
          </table>

          <p>
            We obtain the categories of personal information listed above from
            the following categories of sources:
            <ul>
              <li>
                Directly from you. For example, from forms you complete, or
                products and services you purchase.
              </li>
              <li>
                Indirectly from you. For example, from observing your actions on
                our Websites.
              </li>
              <li>From our affiliates.</li>
              <li>
                From third parties, such as an internet advertiser, a real
                estate lead generator, other real estate brokerages or agents,
                or government entities from which public records are maintained.
              </li>
              <li>
                From our service providers and contractors, who we engage to
                further our business purposes
              </li>
            </ul>
          </p>

          <p>
            We also collect demographic information. Demographic information is
            all other information such as gender, zip code, or any information
            that is not tied to your personal information. In addition, we may
            receive information about you from other online or offline sources,
            including third parties from whom we validate consumer self-reported
            information, and verify information we already have about you. This
            helps us to update, expand and analyze our records and provide
            products and services that may be of interest to you.
          </p>

          <h3 className={classNames("h-echo")}>Use of Personal Information</h3>

          <p>
            We may use or disclose the personal information we collect for one
            or more of the following purposes:
          </p>

          <ul>
            <li>
              To fulfill or meet the reason you provided the information. For
              example, if you share your name and contact information to find an
              agent or ask a question about our products or services, we will
              use that personal information to respond to your inquiry.
            </li>
            <li>
              To provide, support, personalize, and develop our Website,
              products, and services.
            </li>
            <li>
              To create, maintain, customize, and secure your account with us.
            </li>
            <li>
              To process your requests, purchases, transactions, and payments
              and prevent transactional fraud.
            </li>
            <li>
              To provide you with support and to respond to your inquiries,
              including to investigate and address your concerns and monitor and
              improve our responses.
            </li>
            <li>
              To personalize your Website experience and to deliver content and
              product and service offerings relevant to your interests,
              including targeted offers and ads through our Website, third-party
              sites, and via email or text message (with your consent, where
              required by law).
            </li>
            <li>
              To help maintain the safety, security, and integrity of our
              Website, products and services, databases and other technology
              assets, and business.
            </li>
            <li>
              For testing, research, analysis, and product development,
              including to develop and improve our Website, products, and
              services.
            </li>
            <li>
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </li>
            <li>
              As described to you when collecting your personal information or
              as otherwise set forth in the CCPA.
            </li>
            <li>
              To evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which personal
              information held by us about our consumers is among the assets
              transferred.
            </li>
            <li>
              For auditing related to counting ad impressions to unique
              visitors, verifying positioning and quality of ad impressions, and
              auditing compliance with this specification and other standards.
            </li>
            <li>
              To help ensure security and integrity to the extent the use of
              your personal information is reasonably necessary and
              proportionate for these purposes.
            </li>
            <li>
              For debugging to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li>
              For short-term, transient use, including, but not limited to,
              nonpersonalized advertising shown as part of your current
              interaction with us, provided that your personal information is
              not disclosed to another third party and is not used to build a
              profile about you or otherwise alter your experience outside the
              current interaction with us.
            </li>
            <li>
              To perform services, including maintaining or servicing accounts,
              providing customer service, processing or fulfilling orders and
              transactions, verifying customer information, processing payments,
              providing real estate referral services, providing analytic
              services, providing storage, or providing similar services.
            </li>
            <li>
              To provide advertising and marketing services, except for
              cross-context behavioral advertising, to you.
            </li>
            <li>
              For undertaking internal research for technological development
              and demonstration.
            </li>
            <li>
              For undertaking activities to verify or maintain the quality or
              safety of a service or device that is owned, manufactured,
              manufactured for, or controlled by us, and to improve, upgrade, or
              enhance the service or device that is owned, manufactured,
              manufactured for, or controlled by us.
            </li>
            <li>
              To advance a person’s commercial or economic interests, such as by
              inducing another person to buy, rent, lease, join, subscribe to,
              provide, or exchange products, goods, property, information, or
              services, or enabling or effecting, directly or indirectly, a
              commercial transaction
            </li>
          </ul>

          <p>
            We will not collect additional categories of personal information or
            use the personal information we collected for materially different,
            unrelated, or incompatible purposes without providing you notice.
          </p>

          <h3 className={classNames("h-echo")}>
            Use of Sensitive Personal Information
          </h3>
          <p>
            We may use or disclose the sensitive personal information we collect
            for one or more of the following purposes, provided that the use of
            your sensitive personal information is reasonably necessary and
            proportionate for this purpose:
          </p>
          <ul>
            <li>
              To perform the services reasonably expected by your request.
            </li>
            <li>
              To prevent, detect, and investigate security incidents that
              compromise the availability, authenticity, integrity, or
              confidentiality of stored or transmitted personal information.
            </li>
            <li>
              To resist malicious, deceptive, fraudulent, or illegal actions
              directed at the business and to prosecute those responsible for
              those actions.
            </li>
            <li>
              To perform services on behalf of our business such as maintaining
              or servicing accounts, providing customer service, verifying
              customer information, processing payments, or providing real
              estate referral services.
            </li>
            <li>To ensure the physical safety of natural persons.</li>
            <li>
              For short-term, transient use, including, but not limited to,
              nonpersonalized advertising shown as part of your current
              interaction with our business.
            </li>
            <li>
              To verify or maintain the quality or safety of a service, that is
              owned, or controlled by us, and to improve, upgrade, or enhance
              the service that is owned, or controlled by us.
            </li>
            <li>
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </li>
            <li>For purposes that do not infer characteristics about you.</li>
          </ul>
          <p>
            We will not collect additional categories of sensitive personal
            information or use the sensitive personal information we have
            collected for materially different, unrelated, or incompatible
            purposes without providing you notice.
          </p>

          <h3 className={classNames("h-echo")}>
            Disclosing Personal Information
          </h3>

          <p>
            We may disclose your personal information to a service provider or
            contractor for a business purpose, subject to your right to opt-out
            of those. When we disclose personal information for a business
            purpose, we enter a contract that describes the purpose and requires
            the recipient to both keep that personal information confidential
            and not use it for any purpose except performing the contract or as
            otherwise permitted by you. The CCPA prohibits third parties who
            purchase the personal information we hold from reselling it unless
            you have received explicit notice and an opportunity to opt-out of
            further sales.
          </p>

          <p>
            We disclose your personal information with the following:
            <ul>
              <li>Service providers.</li>
              <li>Contractors.</li>
              <li>
                Third parties to whom you or your agents authorize us to
                disclose your personal information in connection with products
                or services we and/or third parties provide to you.
              </li>
            </ul>
          </p>

          <h3 className={classNames("h-echo")}>
            Sales of Personal Information
          </h3>

          <p>
            In the preceding twelve (12) months, WeVett Realty has not sold
            personal information.
          </p>

          <h3 className={classNames("h-echo")}>Your Rights and Choices</h3>

          <p>
            The CCPA provides California residents with specific rights
            regarding their personal information. This section describes your
            CCPA rights and explains how to exercise those rights.
          </p>
          <p className={classNames("hide")}>
            California residents who are employees, job applicants, or former
            employees of WeVett Realty
            <a href="https://wevettrealty.com/careers/">
              click here to view our CCPA Notice for Employment Purposes
            </a>
          </p>

          <h4>
            Right to Know Request - Access to Specific Information and Data
            Portability Rights
          </h4>

          <p>
            You have the right to request that we disclose certain information
            to you about our collection and use of your personal information
            over the past 12 months. Once we receive and confirm your verifiable
            consumer request, we will disclose to you:
          </p>

          <ul>
            <li>
              The categories of personal information we collected about you.
            </li>
            <li>
              The categories of sources for the personal information we
              collected about you.
            </li>
            <li>
              Our business or commercial purpose for collecting or selling that
              personal information.
            </li>
            <li>
              The categories of third parties with whom we share that personal
              information.
            </li>
            <li>
              The specific pieces of personal information we collected about
              you.
            </li>
            <li>
              If we disclosed your personal information for a business purpose
              the categories of information we shared.
            </li>
          </ul>

          <p>
            Information we collect or share in our role as a real estate company
            may not be included in your Right to Know response.
          </p>
          <p>
            If you request that we disclose the required information beyond the
            12-month period, then we shall provide that information unless doing
            so proves impossible or would require a disproportionate effort.
            Your right to request required information beyond the 12-month
            period, and our obligation to provide that information, only applies
            to personal information collected on or after January 1, 2022.
          </p>

          <h4>Deletion Request Rights </h4>

          <p>
            You have the right to request that we delete any of your personal
            information that we collected from you and retained, subject to
            certain exceptions. Once we receive and confirm your verifiable
            consumer request, we will delete (and direct our service providers
            to delete) your personal information from our records, unless an
            exception applies.
          </p>

          <p>
            We may deny your deletion request if retaining the information is
            necessary for us or our service provider(s) to:
          </p>

          <ol>
            <li>
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, fulfill the terms of a written
              warranty or product recall conducted in accordance with federal
              law, or otherwise perform our contract with you.
            </li>
            <li>
              Help to ensure security and integrity to the extent the use of the
              consumer's personal information is reasonably necessary and
              proportionate for those purposes
            </li>
            <li>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq.).
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if you previously provided informed
              consent.
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us.
            </li>
            <li>Comply with a legal obligation.</li>
          </ol>

          <h4>Right to Correct Inaccurate Information</h4>
          <p>
            You have the right to request that we correct any inaccurate
            personal information that we maintain about you. Once we receive and
            confirm your verifiable consumer request, we will use commercially
            reasonable efforts to correct the inaccurate personal information as
            directed by you. In responding to your request, we will take into
            account the nature of the personal information, and the purposes of
            the processing of the personal information.
          </p>

          <h4 id="ccpa-rights-requests">
            Exercising California Consumer Rights Requests
          </h4>

          <p>
            To exercise the access, data portability, correction, and deletion
            rights described above, please submit a verifiable consumer request
            to us by either:
          </p>
          <ul>
            <li>Calling us at 1-316-669-5273.</li>
            <li>
              Emailing a CCPA request to
              <a href="mailto:customerservice@wevett.com">
                customerservice@wevett.com
              </a>
            </li>
            <li className={classNames("hide")}>
              Visiting
              <a href="https://wevettrealty.com/ccpa-request/">
                https://wevettrealty.com/ccpa-request/
              </a>
            </li>
          </ul>

          <p>
            Only you, or someone legally authorized to act on your behalf, may
            make a verifiable consumer request related to your personal
            information. You may also make a verifiable consumer request on
            behalf of your minor child.
          </p>

          <p>
            You may only make a verifiable consumer request for access or data
            portability twice within a 12-month period. The verifiable consumer
            request must:
          </p>

          <ul>
            <li>
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative.
            </li>
            <li>
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
          </ul>

          <p>
            We cannot respond to your request or provide you with personal
            information if we cannot verify your identity or authority to make
            the request and confirm the personal information relates to you.
          </p>

          <p>
            Making a verifiable consumer request does not require you to create
            an account with us.
          </p>

          <p>
            We will only use personal information provided in a verifiable
            consumer request to verify the requestor's identity or authority to
            make the request.
          </p>

          <h4>Response Timing and Format</h4>

          <p>
            We endeavor to respond to a verifiable consumer request within
            forty-five (45) days of its receipt. If we require more time (up to
            90 days), we will inform you of the reason and extension period in
            writing.
          </p>

          <p>
            We will deliver our written response by email to the primary email
            address that you submit with your request.
          </p>

          <p>
            Any disclosures we provide will only cover the 12-month period
            preceding the verifiable consumer request's receipt. The response we
            provide will also explain the reasons we cannot comply with a
            request, if applicable. For data portability requests, we will
            select a format to provide your personal information that is readily
            useable and should allow you to transmit the information from one
            entity to another entity without hindrance.
          </p>

          <p>
            We do not charge a fee to process or respond to your verifiable
            consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request.
          </p>

          <h3 className={classNames("h-echo")}>Non-Discrimination</h3>
          <p>
            We will not discriminate against you for exercising any of your CCPA
            rights. Unless permitted by the CCPA, we will not:
          </p>
          <ul>
            <li>Deny you goods or services.</li>
            <li>
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li>
              Provide you a different level or quality of goods or services.
            </li>
            <li>
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
          <h3 className={classNames("h-echo")}>
            Changes to Our Privacy Notice
          </h3>

          <p>
            We reserve the right to amend this privacy notice at our discretion
            and at any time. When we make changes to this privacy notice, we
            will post the updated notice on the Website and update the notice's
            effective date.
            <strong>
              Your continued use of our Website following the posting of changes
              constitutes your acceptance of such changes.
            </strong>
          </p>

          <h3 className={classNames("h-echo")}>Contact Information</h3>

          <p>
            If you have any questions or comments about this notice, the ways in
            which WeVett Realty collects and uses your information described
            above and in our Privacy Policies, your choices and rights regarding
            such use, or wish to exercise your rights under California law,
            please do not hesitate to contact us at:
          </p>

          <p className="contact-block">
            Phone: 316-669-5273 <br />
            Website:
            <a href="https://wevettrealty.com/privacy/">
              https://wevettrealty.com/privacy/
            </a>
            <br />
            Postal Address: <br />
            WeVett Realty <br />
            Attn: WeVett Customer Service <br />
            6828 E Pepperwood St.
            <br />
            Wichita, KS 67226
          </p>
        </div>

        <h2
          id="consumer-privacy-notice"
          className={classNames("h-delta", "text-center")}
        >
          Consumer Privacy Notice
        </h2>

        <div id="privacy_form">
          <table>
            <tr>
              <th>FACTS</th>
              <td>
                <p>
                  <strong>
                    What Does WeVett Realty Do With Your Personal Information?
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <th>Why?</th>
              <td>
                <p>
                  Real estate companies choose how they share your personal
                  information. Federal law gives consumers the right to limit
                  some but not all sharing. Federal law also requires us to tell
                  you how we collect, share, and protect your personal
                  information. Please read this notice carefully to understand
                  what we do.
                </p>
              </td>
            </tr>
            <tr>
              <th>What?</th>
              <td>
                <p>
                  The types of personal information we collect and share may
                  vary. This information can include:
                </p>
                <ul>
                  <li>Military Status</li>
                  <li>Credit Scores</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>How?</th>
              <td>
                All real estate companies need to share customers' personal
                information to run their everyday business. In the section
                below, we list the reasons companies can share their personal
                information; the reasons WeVett Realty chooses to share; and
                whether you can limit this sharing.
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <th>Reasons we can share your personal information</th>
              <th className={classNames("text-center")}>
                Does WeVett Realty share?
              </th>
              <th className={classNames("text-center")}>
                Can you limit this sharing?
              </th>
            </tr>
            <tr>
              <td>
                For our everyday business purposes - such as to process your
                transactions, maintain your account(s), respond to court orders
                and legal investigations, or report to credit bureaus
              </td>
              <td className={classNames("text-center")}>Yes</td>
              <td className={classNames("text-center")}>No</td>
            </tr>
            <tr>
              <td>
                For our marketing purposes - to offer our products and services
                to you
              </td>
              <td className={classNames("text-center")}>Yes</td>
              <td className={classNames("text-center")}>No</td>
            </tr>
            <tr>
              <td>For joint marketing with other real estate companies</td>
              <td className={classNames("text-center")}>No</td>
              <td className={classNames("text-center")}>We Don't Share</td>
            </tr>
            <tr>
              <td>
                For our affiliates' everyday business purposes - information
                about your transactions and experiences
              </td>
              <td className={classNames("text-center")}>Yes</td>
              <td className={classNames("text-center")}>No</td>
            </tr>
            <tr>
              <td>
                For our affiliates' everyday business purposes - information
                about your creditworthiness
              </td>
              <td className={classNames("text-center")}>Yes</td>
              <td className={classNames("text-center")}>Yes</td>
            </tr>
            <tr>
              <td>For our affiliates to market to you</td>
              <td className={classNames("text-center")}>Yes</td>
              <td className={classNames("text-center")}>Yes</td>
            </tr>
            <tr>
              <td>For nonaffiliates to market to you</td>
              <td className={classNames("text-center")}>No</td>
              <td className={classNames("text-center")}>We Don't Share</td>
            </tr>
          </table>
          <table>
            <tr>
              <th>To&nbsp;limit&nbsp;our sharing</th>
              <td>
                <ul>
                  <li>Call 316-669-5273, or</li>
                  <li>
                    Email
                    <a href="customerservice@wevett.com">
                      CustomerService@wevett.com
                    </a>
                    with a request stating that you would like to limit sharing
                    your information.
                  </li>
                  <li className={classNames("hide")}>
                    Visit us online:
                    <a href="https://wevettrealty.com/opt-out/">
                      wevettrealty.com/opt-out/
                    </a>
                  </li>
                </ul>
                <p>
                  <strong>Please note:</strong>
                  <br />
                  If you are a new customer, we can begin sharing your
                  information 30 days from the date we sent this notice. When
                  you are no longer our customer, we continue to share your
                  information as described in this notice. However, you can
                  contact us at any time to limit our sharing.
                </p>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <th>Questions?</th>
              <td>
                Call <strong>316-669-5273 </strong>
                or visit
                <a href="https://wevettrealty.com/privacy/">
                  wevettrealty.com/privacy
                </a>
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <th colspan="2">Who we are</th>
            </tr>
            <tr>
              <td>
                <strong>Who is providing this notice? </strong>
              </td>
              <td>
                <strong>WeVett Realty</strong>
              </td>
            </tr>
            <tr>
              <th colspan="2">What we do</th>
            </tr>
            <tr>
              <td>
                <strong>
                  How does WeVett Realty protect my personal information?
                </strong>
              </td>
              <td>
                <p>
                  To protect your personal information from unauthorized access
                  and use, we use security measures that comply with federal
                  law. These measures include computer safeguards and secured
                  files and buildings.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  How does WeVett Realty collect my personal information?
                </strong>
              </td>
              <td>
                <p>
                  We collect your personal information, for example, when you
                </p>
                <ul>
                  <li>Apply for us to match you with a qualified agent</li>
                  <li>
                    Provide credit score or show your government-issued ID
                  </li>
                  <li>Give us your contact information</li>
                </ul>
                <p>
                  We also collect your personal information from others, such as
                  Real Estate Brokerages and Agents, affiliates, or other
                  companies.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Why can't I limit all sharing?</strong>
              </td>
              <td>
                <p>Federal law gives you the right to limit only</p>
                <ul>
                  <li>
                    sharing for affiliates' everyday business purposes —
                    information about your creditworthiness
                  </li>
                  <li>
                    affiliates from using your information to market to you
                  </li>
                  <li>sharing for nonaffiliates to market to you</li>
                </ul>
                <p>
                  State laws and individual companies may give you additional
                  rights to limit sharing. See below for more on your rights
                  under state law.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  What happens when I limit sharing for an account I hold
                  jointly with someone else?
                </strong>
              </td>
              <td>
                <p>Your choices will apply to everyone on your account.</p>
              </td>
            </tr>
            <tr>
              <th colspan="2">Definitions</th>
            </tr>
            <tr>
              <td>
                <strong>Affiliates</strong>
              </td>
              <td>
                <p>
                  Companies related by common ownership or control. They can be
                  real estate, financial and nonfinancial companies.
                </p>
                <ul>
                  <li>
                    Our affiliates include companies with a WeVett name,
                    primarily WeVett Home Loans.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Nonaffiliates</strong>
              </td>
              <td>
                <p>
                  Companies not related by common ownership or control. They can
                  be real estate, financial and nonfinancial companies.
                </p>
                <ul>
                  <li>
                    WeVett Realty does not share with nonaffiliates so they can
                    market to you.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Joint marketing</strong>
              </td>
              <td>
                <p>
                  A formal agreement between nonaffiliated real estate,
                  financial or nonfinancial companies that together market real
                  estate related products or services to you.
                </p>
                <ul>
                  <li>WeVett Realty does not jointly market.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th colspan="2">Other important information</th>
            </tr>
            <tr>
              <td colspan="2">
                <p>
                  <strong>Vermont Residents –</strong>
                  Except as permitted by law, we will not share information we
                  collect about you with nonaffiliates or affiliates.
                </p>
                <p>
                  <strong>California Residents –</strong>
                  Except as permitted by law, we will not share information we
                  collect about you with nonaffiliates or joint marketing
                  partners while you are a resident of California.
                </p>
                <p>
                  <strong>Nevada Residents –</strong>
                  Call 1-316-669-5273 to be placed on our Do-Not-Call list. For
                  more information, mail WeVett Customer Service <br />
                  6828 E Pepperwood St.
                  <br />
                  Wichita, KS 67226 or email customerservice@wevett.com. You may
                  also contact the Bureau of Consumer Protection, Office of the
                  Nevada Attorney General, 555 E. Washington Ave., Suite 3900,
                  Las Vegas, NV 89101, call 702-486-3132, or email
                  bcpinfo@ag.state.nv.us. This notice is provided pursuant to
                  state law.
                </p>

                <p>
                  <strong>North Dakota Residents</strong> – Except as permitted
                  by law, we will not share information we collect about you
                  with nonaffiliates without your authorization.
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}
