import React, { Component } from "react";
import ReactDOM from "react-dom";
import MediaQuery from "react-responsive";
// core components
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

function Square({ agent, fileIndex, gridRef }) {
  var agentBubble = "";
  if (!fileIndex) {
    fileIndex = 1;
  }
  if (agent) {
    agentBubble = (
      <div className={classNames("itemBubble")}>
        <div className={classNames("inner")}>
          <img
            alt={`Realtor ${fileIndex}`}
            src={require("assets/img/test-images/clientSuccess/agent" +
              fileIndex +
              ".jpg")}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classNames("gridItem")}>
      {agentBubble}
      <div className={classNames("itemInner")}>
        <MediaQuery minWidth={980}>
          {(matches) =>
            matches ? (
              <img
                alt={`Client Success ${fileIndex}`}
                src={require(`assets/img/clientSuccess/success${fileIndex}.png`)}
                className={classNames("masImg")}
              />
            ) : (
              <img
                alt={`Client Success ${fileIndex}`}
                src={require(`assets/img/clientSuccess/success${fileIndex}.png`)}
                className={classNames("masImg")}
              />
            )
          }
        </MediaQuery>
      </div>
    </div>
  );
}
/*
function Square2() {
	return (<div className={classNames("gridItem")}>
		<div className={classNames("itemInner")}>
			<img alt="image_2" src={require(`assets/img/clientSuccess/success(2).png`)} className={classNames("masImg")} />
			<div><p>I wish I knew about WeVett sooner!</p></div>
		</div>
	</div>);
}

function Square3() {
	return (<div className={classNames("gridItem")}>
		<div className={classNames("itemInner")}>
			<img alt="image_1" src={require(`assets/img/clientSuccess/success(3).png`)} className={classNames("masImg")} />

		</div>
	</div>);
}
*/

class MasonryGrid extends Component {
  constructor(props) {
    super(props);

    let unshuffled = [
      <Square fileIndex={1} />,
      <Square fileIndex={2} />,
      <Square fileIndex={3} />,
      <Square fileIndex={4} />,
      <Square fileIndex={5} />,
      <Square fileIndex={6} />,
      <Square fileIndex={7} />,
      <Square fileIndex={8} />,
      <Square fileIndex={9} />,
      <Square fileIndex={10} />,
      <Square fileIndex={11} />,
      <Square fileIndex={12} />,
      <Square fileIndex={13} />,
      <Square fileIndex={14} />,
      <Square fileIndex={15} />,
      <Square fileIndex={16} />,
      <Square fileIndex={17} />,
      <Square fileIndex={18} />,
      <Square fileIndex={19} />,
      <Square fileIndex={20} />,
      <Square fileIndex={21} />,
      <Square fileIndex={22} />,
      <Square fileIndex={23} />,
      <Square fileIndex={24} />,
      <Square fileIndex={25} />,
      <Square fileIndex={26} />,
    ];

    let shuffled = unshuffled
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    this.state = {
      items: shuffled, // Array of items to prepend to the grid
      currentIndex: 0, // Current index in the items array
    };

    this.gridRef = React.createRef(); // Ref to the masonry grid container
    this.gridInstance = null; // Masonry grid instance
  }

  componentDidMount() {
    // Initialize the masonry grid
    this.initMasonryGrid();

    /*
		//dynamically assign images / assets to the squares
		const initialItems = [];
		for (let i = 1; i <= 29; i++) {
			initialItems.push(<Square fileIndex={i} key={i} />);
		}
		this.setState({ items: initialItems });

		for (let i = 1; items.length; i++) {
			this.addItemToGrid(); //initialize grid
		}
		*/
    // Start the timer to add items every 10 seconds
    //this.state.items.sort(function(){return 0.5 - Math.random()});
    this.addItemInterval = setInterval(this.addItemToGrid, 12000);
    //this.handleImagesLoaded(); //force immediate layout
  }

  componentWillUnmount() {
    // Clear the timer and destroy the masonry grid when the component is unmounted
    clearInterval(this.addItemInterval);
    this.gridInstance.destroy();
  }

  initMasonryGrid = () => {
    this.gridInstance = new Masonry(this.gridRef.current, {
      itemSelector: ".grid-item",
      columnWidth: ".gridSizer",
      stamp: ".stamp",
      stagger: 50,
      transitionDuration: "0.8s",
      percentPosition: true,
      // Add your masonry options here
    });
  };

  handleImagesLoaded = () => {
    // Force Masonry to relayout once all images are loaded
    this.gridInstance.layout();
  };

  addItemToGrid = () => {
    const { items, currentIndex } = this.state;
    const maxItems = 12; // Maximum number of items to keep in the grid

    // If there are items in the array, prepend the next item to the grid
    if (items.length > 0) {
      const newItem = items[currentIndex];

      // Create a new grid item element with the React component
      const gridItem = document.createElement("div");
      gridItem.className = "grid-item";

      // Render the React component into the DOM element
      ReactDOM.render(newItem, gridItem);
      //console.log(this.gridInstance);
      /*
						// Add the new grid item element to the grid
						this.gridInstance.element.insertBefore(gridItem, this.gridInstance.element.firstChild);
						this.gridInstance.prepended(gridItem);
			
						// Update the currentIndex to cycle through items
						const nextIndex = (currentIndex + 1) % items.length;
						this.setState({ currentIndex: nextIndex });
			
						// Manage item count and remove the oldest item if necessary
						const gridItems = this.gridInstance.getItemElements();
						if (gridItems.length >= maxItems) {
							const old_index = gridItems.length - 1;
							const oldestItem = gridItems[old_index]; //.element;
							//console.log("Oldest Item: " + old_index);
							//console.log(oldestItem);
							
							this.gridInstance.remove(oldestItem);
							this.gridInstance.layout();
						}
			*/
      // Use imagesLoaded to wait for all images to load before updating the layout
      /*
			var imgLoad = imagesLoaded(gridItem);
			imgLoad.on('done', function(){
				// Add the new grid item element to the grid
				this.gridInstance.element.insertBefore(gridItem, this.gridInstance.element.firstChild);
				this.gridInstance.prepended(gridItem);
		
				// Update the currentIndex to cycle through items
				const nextIndex = (currentIndex + 1) % items.length;
				this.setState({ currentIndex: nextIndex });
		
				// Manage item count and remove the oldest item if necessary
				const gridItems = this.gridInstance.getItemElements();
				if (gridItems.length >= maxItems) {
				  const oldestItem = gridItems[gridItems.length - 1]; //.element;
				  //console.log(oldestItem);
				  this.gridInstance.remove(oldestItem);
				  //this.gridInstance.removeChild(this.gridInstance.items[0]);
				  
				  //oldestItem.remove();
				 //document.remove(oldestItem);
				  this.gridInstance.layout();
				}
			})
			*/

      imagesLoaded(gridItem, () => {
        // Add the new grid item element to the grid
        this.gridInstance.element.insertBefore(
          gridItem,
          this.gridInstance.element.firstChild
        );
        //this.gridInstance.element.insertBefore(gridItem, this.gridInstance.element.lastChild);
        this.gridInstance.prepended(gridItem);
        //this.gridInstance.appended(gridItem);

        // Update the currentIndex to cycle through items
        const nextIndex = (currentIndex + 1) % items.length;
        this.setState({ currentIndex: nextIndex });

        // Manage item count and remove the oldest item if necessary
        const gridItems = this.gridInstance.getItemElements();
        if (gridItems.length >= maxItems) {
          const oldestItem = gridItems[gridItems.length - 1]; //.element;
          //console.log(oldestItem);
          this.gridInstance.remove(oldestItem);
          //this.gridInstance.removeChild(this.gridInstance.items[0]);

          //oldestItem.remove();
          //document.remove(oldestItem);
          this.gridInstance.layout();
        }
      });
    }
  };

  render() {
    return (
      <div className={classNames("masOuterCont", "section")}>
        <div className={classNames("botFadeDown")}></div>
        <div className={classNames("masCont")}>
          <div className={classNames("mobile-only", "mobileStampHeader")}>
            <h1>Connect with a military-specialized real estate agent.</h1>
            <Button
              type="button"
              color="secondary"
              className={classNames("client-form-btn", "stampBtn")}
            >
              Find Your Agent
            </Button>
          </div>
          <div
            className={classNames("grid", "masonry-container")}
            ref={this.gridRef}
          >
            <div className={classNames("stamp", "stampHeader")}>
              <h1>Connect with a military-specialized real estate agent.</h1>
              <Button
                type="button"
                color="secondary"
                className={classNames("client-form-btn", "stampBtn")}
              >
                Find Your Agent
              </Button>
            </div>

            <div className={classNames("stamp", "stampVertical")}></div>
            <div className={classNames("gridSizer")}></div>
            <div className={classNames("gutterSizer")}></div>

            {/* Existing items in the grid */}
            {this.state.items.map((item, index, array) => (
              <div
                className="grid-item"
                key={this.state.items.length - 1 - index}
                onLoad={() =>
                  imagesLoaded(
                    this.state.items[this.state.items.length - 1 - index],
                    this.handleImagesLoaded
                  )
                }
              >
                {this.state.items[this.state.items.length - 1 - index]}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default MasonryGrid;
